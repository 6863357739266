import * as cz from '../i18n/cz.json';
import * as de from '../i18n/de.json';
import * as en from '../i18n/en.json';
import * as nl from '../i18n/nl.json';

const keyName = 'data-i18n-key';
const buttonName = 'data-i18n-button';
const localStorageKey = 'localStored';
const translations = {
  cz,
  de,
  en,
  nl
};

const languages = {
  'en': '🇬🇧',
  'cz': '🇨🇿',
  'nl': '🇳🇱',
  'de': '🇩🇪'
};

let currentLocale;

/**
 * Returns the locale as it was set in the lang attribute of the HTML element
 *
 * @returns {string} the locale as a string e.g. `en` or `cz` - defaults to 'en'
 */
function getDefaultLocale() {
  const htmlElement = document.querySelector('html');
  return htmlElement.getAttribute('lang') || 'en';
  // TODO do we want to check meta tags?
  //   <meta name="language" content="en"/>
}

/**
 * Returns the locale
 *
 * @returns {string[]} the locale as a string e.g. `en` or `cz`
 */
function getLocales() {
  const locales = new Set();
  navigator.languages.forEach((locale) =>
    locales.add(locale.split('-')[0])
  );
  return Array.from(locales);
}

/**
 * Returns the locale first from localStorage, if non-existent from the browser
 * if that doesn't work out it returns the default locale
 *
 * @returns {string} the locale, e.g. `en` or `cz`
 */
function getLocale() {
  let locale;

  // look in local storage
  locale = window.localStorage.getItem(localStorageKey);

  // if not found check browser locale
  if(!locale) {
    locale = getLocales()[0];
  }

  // if nothing found or not available return default;
  if(!locale || !translations[locale]) {
    locale = getDefaultLocale();
  }

  return locale;
}

/**
 * Sets the provided locale to localStorage and also update the `lang`
 * attribute of the `HTML` tag
 *
 * @param {string} locale -  the locale to set, e.g. `cz`
 */
function setLocale(locale) {
  currentLocale = locale;
  window.localStorage.setItem(localStorageKey, locale);
  document.querySelector('html').setAttribute(
    'lang',
    locale
  );
}

/**
 *
 * @param {HTMLElement} element - the DOM element to translate
 * @param {string} locale - the current locale (e.g. `en`)
 */
function translate(element, locale) {
  const key = element.getAttribute(keyName);
  const text = translations[locale][key];

  if(text) {
    element.innerText = text;
  }
}

/**
 * Called whenever a language change button was clicked. `this` refers to the
 * clicked button
 */
function onChangeLanguage() {
  const language = this.getAttribute('data-i18n-language');

  if(language !== currentLocale) {
    setLocale(language);
    translateAll(language);
  }
}

/**
 * Translates all texts
 *
 * @param locale {string} the locale to translate to e.g. `cz`
 */
function translateAll(locale) {
  document.querySelectorAll(`[${keyName}]`).forEach(element => {
    translate(element, locale);
  });
}

/**
 * Main i18n function
 */
function i18nMain() {
  const locale = getLocale();
  setLocale(locale);
  translateAll(locale);
  const buttons = document.querySelectorAll(`[${buttonName}]`).forEach(element => {
    element.addEventListener('click', onChangeLanguage);
  });
}

// start the main program
document.addEventListener('DOMContentLoaded', i18nMain);
