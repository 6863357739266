import * as THREE from 'three';

// see here: https://www.vantajs.com/?effect=waves

//
// // import p5 from 'p5';
// import BIRDS from 'vanta/dist/vanta.birds.min';
// import CELLS from 'vanta/dist/vanta.cells.min';
// import CLOUDS from 'vanta/dist/vanta.clouds.min'; // OK
// import CLOUDS2 from 'vanta/dist/vanta.clouds2.min';
// import DOTS from 'vanta/dist/vanta.dots.min';
// import FOG from 'vanta/dist/vanta.fog.min';
// import GLOBE from 'vanta/dist/vanta.globe.min'; // OK
// import HALO from 'vanta/dist/vanta.halo.min';
// import NET from 'vanta/dist/vanta.net.min'; // OK THREE: 121
// import RINGS from 'vanta/dist/vanta.rings.min';
// import RIPPLE from 'vanta/dist/vanta.ripple.min';
// import TOPOLOGY from 'vanta/dist/vanta.topology.min';
// import TRUNK from 'vanta/dist/vanta.trunk.min'; // broken
import WAVES from 'vanta/dist/vanta.waves.min'; // OK THREE: 121

const canvasId = 'background-fx';
let backgroundEffect;

/**
 * whenever the Window resizes...
 */
function onWindowResize() {
  backgroundEffect.resize();
  const canvas = document.querySelector('canvas');

  if(canvas) {
    console.log('>> resize canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }
}

const wavesConfig = {
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0x9d2e4a,
  shininess: 14.00,
  waveHeight: 19.50,
  waveSpeed: 0.1,
  zoom: 0.65
};

function main() {
  // backgroundEffect = BIRDS({
  //   el: '#' + canvasId,
  //   mouseControls: true,
  //   touchControls: true,
  //   gyroControls: false,
  //   minHeight: 200.00,
  //   minWidth: 200.00,
  //   scale: 1.00,
  //   scaleMobile: 1.00,
  //   THREE: THREE
  // });
  // backgroundEffect = CELLS({
  //   el: '#' + canvasId,
  //   mouseControls: true,
  //   touchControls: true,
  //   gyroControls: false,
  //   minHeight: 200.00,
  //   minWidth: 200.00,
  //   scale: 1.00
  // });

  // backgroundEffect = CLOUDS({
  //   el: '#' + canvasId,
  //   gyroControls: true,
  //   THREE: THREE
  // });

  // backgroundEffect = NET({
  //   el: '#' + canvasId,
  //   gyroControls: true,
  //   THREE: THREE,
  //   scale: 1.00,
  //   scaleMobile: 1.00,
  //   color: 0xb3b315,
  //   backgroundColor: 0x4a0606,
  //   points: 8.00,
  //   maxDistance: 24.00,
  //   spacing: 14.00
  // });

  backgroundEffect = WAVES({
    el: '#' + canvasId,
    color: 0x501515, //0x850d23,
    gyroControls: true,
    shininess: 14.00,
    THREE: THREE,
    waveHeight: 21.50,
    waveSpeed: 0.1,
    zoom: 0.9
  });

  // backgroundEffect = WAVES({
  //   el: '#' + canvasId,
  //   mouseControls: true,
  //   touchControls: true,
  //   gyroControls: true,
  //   minHeight: 200.00,
  //   minWidth: 200.00,
  //   scale: 1.00,
  //   scaleMobile: 1.00,
  //   color: 0x874a57
  // });


  onWindowResize();
}

// start the main program
document.addEventListener('DOMContentLoaded', main);
